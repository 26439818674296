
import { Options, Vue } from "vue-class-component";

const ICONS = [
  "🍎",
  "📝",
  "🍏",
  "👨‍🎓",
  "🧑‍🎓",
  "👩‍🎓",
  "👩‍💻",
  "☕",
  "🎱",
  "🧘",
  "😤",
  "😭",
  "🤣",
  "👩‍🏫",
  "🎒",
  "📚",
  "💯",
];

@Options({
  watch: {
    locked(newLocked) {
      if (newLocked) {
        localStorage.total = this.total;
      } else {
        localStorage.removeItem("total");
      }

      localStorage.locked = newLocked ? 1 : 0;
    },
  },
})
export default class HelloWorld extends Vue {
  total = 0 as number | string;
  points_missed = 0 as number | string;
  locked = false;
  icon = "";
  errorMsg = "";

  $refs!: {
    totalRef: HTMLInputElement;
  };

  mounted(): void {
    if (localStorage.total) {
      this.total = localStorage.total;

      // Only lock if we have a total
      if ("locked" in localStorage) {
        this.locked = localStorage.locked && localStorage.locked !== "false";
      }
    }

    this.randomlyChangeIcon();
  }

  get score(): number {
    if (typeof this.total === "string") {
      return NaN;
    }

    if (typeof this.points_missed === "string") {
      return NaN;
    }

    return ((this.total - this.points_missed) / this.total) * 100;
  }

  changeIcon(): void {
    this.icon = ICONS[Math.floor(Math.random() * ICONS.length)];
  }

  randomlyChangeIcon(): void {
    this.changeIcon();

    setTimeout(this.randomlyChangeIcon, Math.random() * 30000);
  }

  toggleLock(): void {
    this.validateTotal();

    if (this.errorMsg === "" || this.locked) {
      this.locked = !this.locked;
    }
  }

  validateTotal(): void {
    let errorMsg = "";

    if (typeof this.total === "string") {
      errorMsg = "Please enter a number";
    } else if (isNaN(this.total) || !isFinite(this.total)) {
      errorMsg = "Please enter a valid number";
    } else if (this.total < 0) {
      errorMsg = "Please enter a number greater than zero";
    }

    this.$refs.totalRef.setCustomValidity(errorMsg);
    this.errorMsg = errorMsg;
  }
}
